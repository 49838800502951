import React from "react";
import { Card, Button, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { MyAPI } from "../../utils/MyAPI";
import { useSelector } from "react-redux";

const AccountDelete = ({ user }) => {
  const token = useSelector((state) => state.token.value);

  const handleDeleteAccount = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone. Your account will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAccount();
      }
    });
  };

  const deleteAccount = async () => {
    try {
      const res = await MyAPI.DELETE("/parent/account-request", token);
      const { status, message } = res.data || res;
      if (status) {
        toast.success("Account delete request has been submitted.");
      } else {
        toast.error(message || "Failed to request account deletion.");
      }
    } catch (error) {
      toast.error(error.message || "Server error occurred.");
    }
  };

  return (
    <Card className="text-start border rounded-3 shadow-sm mt-5 shadow border-2">
      <Card.Header className="bg-danger text-white">
        <b>Account Information</b>
      </Card.Header>
      <Card.Body>
        <Card.Title>{user?.visibleName || "User"}</Card.Title>
        <Card.Text>
          Email: {user?.email || "Not available"} <br />
          Last Active:{" "}
          {user?.lastActive
            ? new Date(user.lastActive).toLocaleDateString()
            : "Unknown"}
        </Card.Text>
        {user?.status === "Req-Delete" ? (
          <Alert variant="danger">
            Your account deletion request is already under process.
          </Alert>
        ) : (
          <Button variant="danger" onClick={handleDeleteAccount}>
            Delete Account
          </Button>
        )}
      </Card.Body>
      <Card.Footer className="text-muted">
        Accounts inactive for 6 months may be deleted automatically.
      </Card.Footer>
    </Card>
  );
};

export default AccountDelete;