import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import styled from "styled-components";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import Upload from "../courseManagement/Upload";
import Progress from "../courseManagement/Progress";
import ParentFooter from "../../components/ParentFooter";
import { toast } from "react-toastify";
import { baseAwsUrl, MyAPI, truncateText } from "../../utils/MyAPI";
import { useSelector } from "react-redux";
import axios from "../../utils/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";
import { MdCloudUpload } from "react-icons/md";
import { IoMdCloudDownload } from "react-icons/io";
import { ImFilesEmpty } from "react-icons/im";
const EditClassDetails = () => {
  let { id } = useParams()
  const navigate = useNavigate()
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [grade, setGrade] = useState("");
  const [board, setBoard] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [type, setType] = useState("");
  const [language, setLanguage] = useState("");
  const [title, setTitle] = useState("");
  const [objective, setObjective] = useState("");
  const [description, setDescription] = useState("");
  const [module, setModule] = useState("");
  const [tags, setTags] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  const [worksheets, setWorksheets] = useState([]);
  const [durationTime, setDurationTime] = useState("");

  const [isThumUpdate, setIsThumUpdate] = useState(false)
  const [isIntroVideoUpdate, setIsIntroVideoUpdate] = useState(false)
  const [isWorkSitsUpdate, setIsWorkSitsUpdate] = useState(false)

  const handlechangeWorksheets = (e) => {
    setWorksheets(e.target.files);
  };

  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.token.value);

  // set states of dataFetching
  const [allCourses, setAllCourses] = useState([]);
  const [allBoards, setAllBoards] = useState([]);
  const [allQualification, setAllQualification] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedQualification, setSelectedQualification] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [rate, setRate] = useState(null);

  const fetchRecordsData = async (token, record) => {
    try {
      let res = await MyAPI.GET(`/common/records?record_type=${record}`, token);
      let { res_type, message, data } = res.data || res;
      if (res_type === "success") {
        if (record === "course") {
          setAllCourses(data);
        } else if (record === "qualification") {
          setAllQualification(data);
        } else if (record === "grade") {
          setAllGrades(data);
        } else if (record === "board") {
          setAllBoards(data)
        }
      } else {
        toast.error(message || "Error Fetching Records.");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchAllRateRecords = async (token, subject, grade, board) => {
    try {
      setDataLoading(true);
      let res = await MyAPI.GET(
        `/common/price-suggestion?course=${subject}&grade=${grade}&qualification=${board}`,
        token
      );
      let { res_type, message, data } = res.data || res;
      if (res_type === "success") {
        setPrice(data?.amount || 0);
        setRate(data);
      } else {
        toast.error(message || "Error Fetching Records.");
      }
      console.log("records", res.data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (token && board && subject && grade) {
      fetchAllRateRecords(token, subject, grade, board);
    }
  }, [token, board, subject, grade]);

  useEffect(() => {
    if (token) {
      fetchRecordsData(token, "course");
      fetchRecordsData(token, "qualification");
      fetchRecordsData(token, "grade");
      fetchRecordsData(token, "board");
    }
  }, [token]);

  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes
  const ALLOWED_VIDEO_TYPES = ["video/mp4", "video/avi", "video/mov"]; // Add allowed video types here

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Check if a file was selected
    if (selectedFile) {
      // Check file size
      if (selectedFile.size > MAX_FILE_SIZE) {
        toast.error("Intro video file size exceeds the 50 MB limit.");
        setVideo(null); // Clear the previous video file
        return;
      }

      // Check file type
      if (!ALLOWED_VIDEO_TYPES.includes(selectedFile.type)) {
        toast.error("Invalid video type. Please upload a valid video.");
        setVideo(null); // Clear the previous video file
        return;
      }

      // If file is valid, set the video state
      setVideo(selectedFile);
    }
  };

  const HandleSubmit = async () => {

    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB in bytes

    // Check file sizes before appending them to FormData
    if (isThumUpdate) {
      if (thumbnail && thumbnail.size > MAX_FILE_SIZE) {
        toast.error("Thumbnail file size exceeds the 50 MB limit.");
        return;
      }
    }

    if (isIntroVideoUpdate) {
      if (video && video.size > MAX_FILE_SIZE) {
        toast.error("Intro video file size exceeds the 50 MB limit.");
        return;
      }
    }

    if (isWorkSitsUpdate) {
      for (let i = 0; i < worksheets?.length; i++) {
        if (worksheets[i].size > MAX_FILE_SIZE) {
          toast.error(`Worksheet file ${i + 1} exceeds the 50 MB limit.`);
          return;
        }
      }
    }

    // if (!ageGroup && course?.ageGroup) {
    //   toast.error("Please select age group");
    //   return;
    // }

    const formData = new FormData();
    formData.append("category", category || course?.category);
    formData.append("subject", subject || course?.subject);
    formData.append("grade", grade || course?.grade);
    formData.append("board", board || course?.board);
    formData.append("qualification", selectedQualification || course?.qualification);
    formData.append("s_price", price || course?.s_price);
    formData.append("duration", duration || course?.duration);
    formData.append("duration_time", durationTime || course?.duration_time);
    formData.append("course_type", type || course?.course_type);
    formData.append("language", language || course?.language);
    formData.append("title", title || course?.title);
    formData.append("objective", objective || course?.objective);
    formData.append("description", description || course?.description);
    formData.append("modules", module || course?.modules?.join(', '));
    formData.append("ageGroup", ageGroup || course?.ageGroup);
    formData.append("course_tags", tags || course?.course_tags?.join(', '));
    formData.append("price", coursePrice || course?.price);
    // recieve the file and append it to the form data
    if (isThumUpdate) {
      formData.append("thumbnail", thumbnail);
    }
    if (isIntroVideoUpdate) {
      formData.append("intro_video", video);
    }
    if (isWorkSitsUpdate) {
      for (let i = 0; i < worksheets?.length; i++) {
        formData.append("work_sheets", worksheets[i]);
      }
    }

    // send the form data to the backend

    try {
      setLoading(true);
      const res = await axios.put(`/teacher/course?courseId=${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        setCategory("");
        setSubject("");
        setGrade("");
        setBoard("");
        setPrice("");
        setDuration("");
        setType("");
        setLanguage("");
        setTitle("");
        setObjective("");
        setDescription("");
        setModule("");
        setTags("");
        setCoursePrice("");
        setThumbnail(null);
        setVideo(null);
        setWorksheets([]);
        navigate('/teacher/courses')
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message || 'Server Error Please try again later.');
    } finally {
      setLoading(false);
    }
  };


  const [course, setCourse] = useState(null)

  const fetchCourseData = async (token, id) => {
    try {
      let res = await MyAPI.GET(`/teacher/course-id/${id}`, token)
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setCourse(apiData);
      } else {
        toast.error(message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (token && id) {
      fetchCourseData(token, id)
    }
  }, [token, id])

  return (
    <TeacherLayout>
      <h3>Edit Course</h3>


      <StyledContainer>
        <Row className="mt-5">
          <Col md={6}>
            <Row>
              <Col>
                <p className="m-0">Category:</p>
              </Col>
            </Row>
            <Row className="pe-5">
              <Col className="d-flex align-items-center jsutify-content-between mt-3 pe-5">
                <Form.Group>
                  <Form.Select
                    className="shadow rounded-3"
                    name="subject"
                    onChange={(e) => setCategory(e.target.value)}
                    value={category || course?.category}
                  >
                    <option>Subject</option>
                    <option value="Academics">Academics</option>
                    <option value="EAcademics">Extra Academics</option>
                    <option value="Curricular">Curricular</option>
                    <option value="ECurricular">Extra Curricular</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3>Course Content</h3>
          </Col>
        </Row>

        {/* <form> */}
        <Row className="mt-3">
          <Col md={3}>
            <Form.Group>
              <Form.Select
                className="shadow rounded-3"
                name="Subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject || course?.subject}
              >
                <option>Subject</option>
                {allCourses &&
                  allCourses?.length > 0 &&
                  allCourses.map((item, index) => (
                    <option
                      selected={subject === item.title}
                      key={index}
                      value={item.title}
                    >
                      {item.title}
                    </option>
                  ))}
                {allCourses && allCourses?.length === 0 && (
                  <option>No Data Found.</option>
                )}
              </Form.Select>
            </Form.Group>
          </Col>

          {/* make searchable dropdown */}

          <Col md={3}>
            <Form.Group>
              <Form.Select
                className="shadow rounded-3"
                name="grade"
                onChange={(e) => setGrade(e.target.value)}
                value={grade || course?.grade}
              >
                <option>Grade</option>
                {allGrades &&
                  allGrades?.length > 0 &&
                  allGrades.map((item, index) => (
                    <option
                      selected={grade === item.title}
                      key={index}
                      value={item.title}
                    >
                      {item.title}
                    </option>
                  ))}
                {allGrades && allGrades?.length === 0 && (
                  <option>No Data Found.</option>
                )}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group>
              <Form.Select
                className="shadow rounded-3"
                name="board"
                onChange={(e) => setBoard(e.target.value)}
                value={board || course?.board}
              >
                <option>Board</option>
                {allBoards &&
                  allBoards?.length > 0 &&
                  allBoards.map((item, index) => (
                    <option
                      key={index}
                      value={item.title}
                    >
                      {item.title}
                    </option>
                  ))}
                {allBoards && allBoards?.length === 0 && (
                  <option>No Data Found.</option>
                )}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group>
              <Form.Select
                className="shadow rounded-3"
                name="board"
                onChange={(e) => setSelectedQualification(e.target.value)}
                value={selectedQualification || course?.qualification}
              >
                <option>Qualification</option>
                {allQualification &&
                  allQualification?.length > 0 &&
                  allQualification.map((item, index) => (
                    <option
                      key={index}
                      value={item.title}
                    >
                      {item.title}
                    </option>
                  ))}
                {allQualification && allQualification?.length === 0 && (
                  <option>No Data Found.</option>
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Select
                className="shadow rounded-3 mt-4"
                name="ageGroup"
                onChange={(e) => setAgeGroup(e.target.value)}
                value={ageGroup || course?.ageGroup}
              >
                <option>Age Group</option>
                <option value="6-10 years">6-10 years</option>
                <option value="11-15 years">11-15 years</option>
                <option value="16-20 years">16-20 years</option>
                <option value="21-25 years">21-25 years</option>
                <option value="26-30 years">26-30 years</option>
              </Form.Select>
            </Form.Group>
          </Col>


          <Col className="mt-4" md={3}>
            <Form.Group>
              <Form.Control
                className="shadow rounded-3"
                type="text"
                disabled
                placeholder="suggested price "
                onChange={(e) => setPrice(e.target.value) || '00'}
                value={price || course?.price}
              />
            </Form.Group>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Group>
              <Form.Select
                name="duration"
                className="shadow"
                onChange={(e) => setDuration(e.target.value)}
                value={duration || course?.duration}
              >
                <option>Duration</option>
                <option value="On Going">On Going</option>
                <option value="One Time">One Time</option>
              </Form.Select>
            </Form.Group>
          </Col>

          {duration === "One Time" && (
            <Col md={3} className="mt-4">
              <Form.Group>
                <Form.Control
                  className="shadow rounded-3 me-4"
                  type="text"
                  placeholder="Enter hours"
                />
              </Form.Group>
            </Col>
          )}

          <Col md={3} className="mt-4">
            <Form.Group>
              <Form.Select
                name="type"
                className="shadow"
                onChange={(e) => setType(e.target.value)}
                value={type || course?.course_type}
              >
                <option>Type</option>
                <option value="1-On-1">1-On-1 Classes</option>
                <option value="Group">Group Classes</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Group>
              <Form.Select
                name="language"
                className=" shadow"
                onChange={(e) => setLanguage(e.target.value)}
                value={language || course?.language}
              >
                <option>Language</option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={3} className="mt-4">
            <input
              type="text"
              className="form-control shadow"
              placeholder="Duration time"
              onChange={(e) => setDurationTime(e.target.value)}
              value={durationTime || course?.duration_time}
            />
          </Col>

          <Col md={12} className="mt-4">
            <input
              type="text"
              className="form-control shadow"
              placeholder="Course Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title || course?.title}
            />
          </Col>
          <Col md={12} className="mt-4">
            <input
              type="text"
              className="form-control shadow"
              placeholder="Objective"
              onChange={(e) => setObjective(e.target.value)}
              value={objective || course?.objective}
            />
          </Col>
          <Col md={12} className="mt-4">
            <textarea
              class="form-control shadow"
              rows="5"
              onChange={(e) => setDescription(e.target.value)}
              value={description || course?.description}
            ></textarea>
          </Col>
          <Col md={3} className="mt-4">
            <input
              type="text"
              className="form-control shadow"
              placeholder="Modules"
              onChange={(e) => setModule(e.target.value)}
              value={module || course?.modules?.join(', ')}
            />
          </Col>
          <Col md={6} className="mt-4">
            <input
              type="text"
              className="form-control shadow"
              placeholder="Course Tags"
              onChange={(e) => setTags(e.target.value)}
              value={tags || course?.course_tags?.join(', ')}
            />
          </Col>
          <Col md={3} className="mt-4">
            <input
              type="text"
              className="form-control shadow"
              placeholder="Price"
              onChange={(e) => setCoursePrice(e.target.value)}
              value={coursePrice || course?.price}
            />
          </Col>
        </Row>
        {/* </form> */}


        <Row className="mt-5">
          <Col md={8}>
            <h3>Course Thumbnail</h3>
          </Col>
          <Col className="float-end d-flex align-items-center justify-content-between gap-2" md={4}>
            <FormControlLabel
              checked={isThumUpdate}
              onChange={() => setIsThumUpdate(!isThumUpdate)}
              control={
                <Switch name="gilad" />
              }
              label="Update Thumbnail"
            />
          </Col>
        </Row>

        <Row className="mt-3">
          {
            !isThumUpdate ? (
              <Col md={12}>
                <img alt="my-im" style={{ maxHeight: '320px' }} src={baseAwsUrl + course?.thumbnail_key} controls />
                {/* <video style={{ maxHeight: '320px' }} src={baseAwsUrl + course?.intro_video_key} controls /> */}
              </Col>
            ) : (
              <>
                <Col md={6}>
                  <div className="upload_box">
                    <MdCloudUpload className="text_yellow fs-1 mb-2" />
                    <p className="m-0">Drag files or upload</p>
                    <p className="small_text m-0 my-2">or</p>


                    <input
                      type="file"
                      className="upload-file-box"
                      onChange={(e) => setThumbnail(e.target.files[0])}
                    />

                    <p className="m-0 mb-2">
                      Max file size: <b>50MB</b>{" "}
                    </p>
                    <p className="m-0 mb-2">
                      Supported file types: <b>PNG, JPG, PDF</b>
                    </p>
                  </div>
                </Col>
                <Col md={6} className="pt-5">
                  <Progress />
                </Col>
              </>
            )
          }
        </Row>

        <Row className="mt-5">
          <Col md={8}>
            <h3>Course intro video</h3>
          </Col>
          <Col className="float-end d-flex align-items-center justify-content-between gap-2" md={4}>
            <FormControlLabel
              checked={isIntroVideoUpdate}
              onChange={() => setIsIntroVideoUpdate(!isIntroVideoUpdate)}
              control={
                <Switch name="gilad" />
              }
              label="Update Intro Video"
            />
          </Col>
        </Row>

        <Row className="mt-3">
          {
            !isIntroVideoUpdate ? (
              <Col md={12}>
                {/* <img alt="my-im" style={{ maxHeight: '320px' }} src={baseAwsUrl + course?.thumbnail_key} controls /> */}
                <video style={{ maxHeight: '320px' }} src={baseAwsUrl + course?.intro_video_key} controls />
              </Col>
            ) : (<>
              <Col md={6}>
                <div className="upload_box">
                  <MdCloudUpload className="text_yellow fs-1 mb-2" />
                  <p className="m-0">Drag files or upload</p>
                  <p className="small_text m-0 my-2">or</p>

                  <div>
                    <input
                      type="file"
                      className="upload-file-box"
                      onChange={handleFileChange}
                      accept={ALLOWED_VIDEO_TYPES.join(",")}
                    />
                    {video && <p>File selected: {video.name}</p>}
                  </div>

                  <p className="m-0 mb-2">
                    Max file size: <b>50MB</b>{" "}
                  </p>
                  <p className="m-0 mb-2">
                    Supported file types: <b>PNG, JPG, PDF</b>
                  </p>
                </div>
              </Col>
              <Col md={6} className="pt-5">
                <Progress />
              </Col>
            </>)
          }
        </Row>

        <Row className="mt-5">
          <Col md={8}>
            <h3>Course worksheets</h3>
          </Col>
          <Col className="float-end d-flex align-items-center justify-content-between gap-2" md={4}>
            <FormControlLabel
              checked={isWorkSitsUpdate}
              onChange={() => setIsWorkSitsUpdate(!isWorkSitsUpdate)}
              control={
                <Switch name="gilad" />
              }
              label="Update worksheets"
            />
          </Col>
        </Row>

        {
          !isWorkSitsUpdate ? (
            <Row className="mt-3">
              <div className="d-flex flex-wrap gap-3 justify-content-start">
                {course && course.work_sheets_keys && course.work_sheets_keys.length > 0 ? (
                  course.work_sheets_keys.map((file, index) => (
                    <div
                      key={index}
                      className="file-card shadow-sm rounded-3 border d-flex flex-column align-items-center p-3"
                    >
                      <ImFilesEmpty size={32} className="file-icon mb-2" />
                      {/* <p className="file-name text-truncate" title={file}>
                        {truncateText(file, 1)}
                      </p> */}
                      <Link
                        to={baseAwsUrl + file}
                        target="_blank"
                        download
                        className="download-btn mt-2 text-decoration-none"
                      >
                        <IoMdCloudDownload size={22} className="me-1" />
                        Download
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>No files available.</p>
                )}
              </div>
            </Row>
          ) : (
            <Row className="mt-3">
              <Col md={6}>
                <div className="upload_box">
                  <MdCloudUpload className="text_yellow fs-1 mb-2" />
                  <p className="m-0">Drag files or upload</p>
                  <p className="small_text m-0 my-2">or</p>

                  <input
                    type="file"
                    className="upload-file-box"
                    multiple
                    onChange={handlechangeWorksheets}
                  />

                  <p className="m-0 mb-2">
                    Max file size: <b>50MB</b>{" "}
                  </p>
                  <p className="m-0 mb-2">
                    Supported file types: <b>PNG, JPG, PDF</b>
                  </p>
                </div>
              </Col>
              <Col md={6} className="pt-5">
                <Progress />
              </Col>
            </Row >
          )
        }

        <Row className="mt-5 d-flex justify-content-end pe-3">
          {/* <button className="approval_btn text-white">Increase Price</button> */}
          <button onClick={HandleSubmit} className="approval_btn text-white">
            {loading ? "Uploading..." : " Send For Approval"}
          </button>
        </Row>
      </StyledContainer >
      <ParentFooter />
    </TeacherLayout >
  );
};

const StyledContainer = styled.div`
  .dropdown-basic {
    background-color: transparent;
    border: 1px solid var(--color-primary) !important;
    border: none;
    width: 220px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .dropdown-toggle::after {
    position: absolute !important;
    right: 6% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .dropdown-basic:active {
    background-color: transparent !important;
  }

  .dropdown-menu {
    border: none !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .form-control {
    border: 1px solid var(--color-primary) !important;
    border-radius: 10px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .approval_btn {
    background-color: var(--color-primary);
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    width: fit-content;
    line-height: 24px;
    padding: 10px 20px;
    border: none;
  }
`;

export default EditClassDetails;
