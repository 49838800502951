import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoStarSharp } from "react-icons/io5";
import Card2 from "../ParentPages/parent/Card2";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Button, Col, Form, Row } from "react-bootstrap";
import Dropdown from "../ParentPages/courses/Dropdown";
import { CiSearch } from "react-icons/ci";
import { Navigation } from "swiper/modules";
import card2_img from "../assets/parent/card2_img.png";
import { toast } from "react-toastify";
import { MyAPI } from "../utils/MyAPI";

function FindMentor() {
  const [activeDropdown, setActiveDropdown] = useState(null);


  const [subjects, setSubjects] = useState([
    { title: "Maths", id: 1 },
    { title: "Science", id: 2 },
    { title: "English", id: 3 },
  ]);

  const [grades, setGrades] = useState([
    { title: "1", id: 1 },
    { title: "2", id: 2 },
    { title: "3", id: 3 },
  ]);

  const [boards, setBoards] = useState([
    { title: "CBSE", id: 1 },
    { title: "ICSE", id: 2 },
    { title: "State Board", id: 3 },
  ]);

  const [experiences, setExperiences] = useState([
    { title: "1 Year", id: 1 },
    { title: "2 Years", id: 2 },
    { title: "3 Years", id: 3 },
  ]);

  const [ratings, setRatings] = useState([
    { title: "1", id: 1 },
    { title: "2", id: 2 },
    { title: "3", id: 3 },
  ]);

  const [types, setTypes] = useState([
    { title: "1-on-1", id: 1 },
    { title: "Group Classes", id: 2 },
    { title: "Both", id: 3 },
  ]);

  const [days, setDays] = useState([
    { title: "1", id: 1 },
    { title: "2", id: 2 },
    { title: "3", id: 3 },
    { title: "4", id: 4 },
    { title: "5", id: 5 },
    { title: "6", id: 6 },
  ]);

  const [qualifications, setQualifications] = useState([
    { title: "B.Tech", id: 1 },
    { title: "M.Tech", id: 2 },
    { title: "PhD", id: 3 },
  ]);

  const [competitiveExams, setCompetitiveExams] = useState([
    { title: "JEE", id: 1 },
    { title: "NEET", id: 2 },
  ]);

  const fetchRecords = async (record_type) => {
    try {
      let res = await MyAPI.GET(`/home/records?record_type=${record_type}`);
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        if (record_type === "exam") {
          setCompetitiveExams(apiData);
        } else if (record_type === "qualification") {
          setQualifications(apiData);
        } else if (record_type === "board") {
          setBoards(apiData);
        } else if (record_type === "grade") {
          setGrades(apiData);
        } else if (record_type === "subject") {
          setSubjects(apiData);
        }
      } else {
        toast.error(message || `Error fetching records - ${record_type}`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDays, setSelectedDays] = useState("");
  const [selectedQualification, setSelectedQualification] = useState("");
  const [selectedCompetitive, setSelectedCompetitive] = useState("");

  const [allTeachers, setAllTeachers] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [key_word, setKey_word] = useState("");
  const [page, setPage] = useState(1);

  const fetchTeachers = async (
    board,
    experience,
    rating,
    qualification,
    subject,
    page,
    key_word
  ) => {
    try {
      setDataLoading(true);
      let res = await MyAPI.GET(
        `/home/teachers?keyword=${key_word}&page=${page}&limit=20&board=${board}&experience=${experience}&rating=${rating}&qualification=${qualification}&subject=${subject}`
      );
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setAllTeachers([allTeachers, ...apiData.teachers]);
      } else {
        toast.error(message || "Error fetching teachers.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers(
      selectedBoard,
      selectedExperience,
      selectedRating,
      selectedQualification,
      selectedSubject,
      page,
      key_word
    );
  }, [
    selectedBoard,
    selectedExperience,
    selectedRating,
    selectedQualification,
    selectedSubject,
    page,
    key_word,
  ]);


  useState(() => {
    fetchRecords("exam");
    fetchRecords("qualification");
    fetchRecords("board");
    fetchRecords("exam");
    fetchRecords("grade");
    fetchRecords("subject");
  }, []);

  const renderSelect = (label, value, options, onChange) => (
    <Col md={3} className="mt-4">
      <Form.Group controlId={`${label.toLowerCase()}Select`}>
        {/* <Form.Label>{label}</Form.Label> */}
        <Form.Control
          className="shadow rounded-5 border border-3"
          as="select"
          value={value}
          onChange={onChange}
        >
          <option value="">Select {label}</option>
          {options.map((option) => (
            <option key={option?.id || option?._id} value={option.title}>
              {option.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </Col>
  );

  const handleLoadMoreData = () => {
    setPage(page + 1);
  };

  return (
    <>
      <div
        className="main d-block mx-auto"
        style={{ maxWidth: "1400px", overflowX: "hidden" }}
      >
        <Navbar />
        <br />
        <br />
        <br />
        <br />
        <br />
        <StyledContainer className="ps-5">
          <Row className="d-flex justify-content-end pe-5">
            <div className="input_container py-2 px-3">
              <input
                type="text"
                value={key_word}
                onChange={(e) => setKey_word(e.target.value)}
                placeholder="Search Teacher"
                className="border-0"
              />
              <button>
                <CiSearch className="fs-5 text_yellow" />
              </button>
            </div>
          </Row>
          <Row className="pe-5">
            {renderSelect("Subject", selectedSubject, subjects, (e) =>
              setSelectedSubject(e.target.value)
            )}
            {renderSelect("Grade", selectedGrade, grades, (e) =>
              setSelectedGrade(e.target.value)
            )}
            {renderSelect("Board", selectedBoard, boards, (e) =>
              setSelectedBoard(e.target.value)
            )}
            {renderSelect("Experience", selectedExperience, experiences, (e) =>
              setSelectedExperience(e.target.value)
            )}
            {renderSelect("Rating", selectedRating, ratings, (e) =>
              setSelectedRating(e.target.value)
            )}
            {renderSelect("Type", selectedType, types, (e) =>
              setSelectedType(e.target.value)
            )}
            {renderSelect("Days", selectedDays, days, (e) =>
              setSelectedDays(e.target.value)
            )}
            {renderSelect(
              "Qualification",
              selectedQualification,
              qualifications,
              (e) => setSelectedQualification(e.target.value)
            )}
            {renderSelect(
              "Competitive",
              selectedCompetitive,
              competitiveExams,
              (e) => setSelectedCompetitive(e.target.value)
            )}
          </Row>

          <Row className="mt-5">
            <Col>
              <h3 className="mb-4">Teachers</h3>
            </Col>
          </Row>

          <Row>
            {!dataLoading &&
              allTeachers &&
              allTeachers?.length > 0 &&
              allTeachers.map((teacher, index) => (
                <Col key={index} md={3}>
                  <Card2
                    img={teacher.image_key || card2_img}
                    bottomRowText1={
                      teacher?.visibleName || teacher?.firstName || "N/A"
                    }
                    bottomRowText2={"4.3"}
                    bottomRowText2Icon={
                      <IoStarSharp className="text_yellow me-2 fs-6" />
                    }
                    redirect={`/teacher/profile/${teacher._id}`}
                  />
                </Col>
              ))}
            {!dataLoading && allTeachers && allTeachers?.length === 0 && (
              //show a warning no data found.
              <Col md={12}>
                <p className="text-center text-danger">No teachers found.</p>
              </Col>
            )}
            {dataLoading && (
              <Col md={12}>
                <p className="text-center text-primary">Loading...</p>
              </Col>
            )}

            {!dataLoading && allTeachers && allTeachers?.length > 19 && (
              <Col md={12}>
                <Button onClick={handleLoadMoreData} variant="primary">
                  {dataLoading ? "Loading..." : "Load More..."}
                </Button>
              </Col>
            )}
          </Row>

          {/* <Row className="position-relative pe-5">
            <Swiper
              pagination={{ clickable: true }}
              slidesPerView={window.innerWidth <= 768 ? 1 : 3}
              spaceBetween={20}
              navigation={{
                nextEl: ".teacher_right_arrow1",
                prevEl: ".teacher_left_arrow1",
              }}
              modules={[Navigation]}
              className="mySwiper"
              style={{
                paddingRight: "3.8vmax",
              }}
            >
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                  redirect={"/parent/teacher/123456"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                  redirect={"/parent/teacher/123456"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                  redirect={"/parent/teacher/123456"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                  redirect={"/parent/teacher/123456"}
                />
              </SwiperSlide>
            </Swiper>

            <div className="teacher_left_arrow1">
              <FaAngleLeft />
            </div>
            <div className="teacher_right_arrow1">
              <FaAngleRight />
            </div>
          </Row>

          <Row className="mt-5">
            <Col>
              <h3 className="mb-4">IIT JEE Teachers</h3>
            </Col>
          </Row>

          <Row className="position-relative pe-5">
            <Swiper
              pagination={{ clickable: true }}
              slidesPerView={window.innerWidth <= 768 ? 1 : 3}
              spaceBetween={20}
              navigation={{
                nextEl: ".teacher_right_arrow2",
                prevEl: ".teacher_left_arrow2",
              }}
              modules={[Navigation]}
              className="mySwiper"
              style={{
                paddingRight: "3.8vmax",
              }}
            >
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                />
              </SwiperSlide>
            </Swiper>

            <div className="teacher_left_arrow2">
              <FaAngleLeft />
            </div>
            <div className="teacher_right_arrow2">
              <FaAngleRight />
            </div>
          </Row>

          <Row className="mt-5">
            <Col>
              <h3 className="mb-4">NEET Teachers</h3>
            </Col>
          </Row>

          <Row className="position-relative pe-5">
            <Swiper
              pagination={{ clickable: true }}
              slidesPerView={window.innerWidth <= 768 ? 1 : 3}
              spaceBetween={20}
              navigation={{
                nextEl: ".teacher_right_arrow3",
                prevEl: ".teacher_left_arrow3",
              }}
              modules={[Navigation]}
              className="mySwiper"
              style={{
                paddingRight: "3.8vmax",
              }}
            >
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                />
              </SwiperSlide>
              <SwiperSlide>
                <Card2
                  img={card2_img}
                  bottomRowText1={"Vishal Seth"}
                  bottomRowText2={"4.3"}
                  bottomRowText2Icon={
                    <IoStarSharp className="text_yellow me-2 fs-6" />
                  }
                />
              </SwiperSlide>
            </Swiper>

            <div className="teacher_left_arrow3">
              <FaAngleLeft />
            </div>
            <div className="teacher_right_arrow3">
              <FaAngleRight />
            </div>
          </Row> */}
        </StyledContainer>

        <Footer />
      </div>
    </>
  );
}

const StyledContainer = styled.div`
  .teacher_right_arrow2,
  .teacher_left_arrow2,
  .teacher_right_arrow1,
  .teacher_left_arrow1,
  .teacher_right_arrow3,
  .teacher_left_arrow3 {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background: var(--yellow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    > svg {
      color: #000000;
    }
  }
  .teacher_right_arrow2,
  .teacher_right_arrow1,
  .teacher_right_arrow3 {
    right: 5%;
  }
  .teacher_left_arrow2,
  .teacher_left_arrow1,
  .teacher_left_arrow3 {
    left: -1%;
  }
  .input_container {
    border: 2px solid #e5e5e5;
    border-radius: 100px;
    width: fit-content;

    input {
      background: transparent;
      outline: none;
    }

    button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .search_btn_blog {
    button {
      background: var(--color-primary);
      border-radius: 100px;
      border: none;
      outline: none;
      font-weight: 600;
      cursor: pointer;
      width: 100%;
    }
  }
`;

export default FindMentor;
