import axios from "axios";

// Set baseURL conditionally based on the environment (development or production)
const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000" // Development API URL
      : "https://urja-talents-backend-xtk2.onrender.com", // Production API URL
});

export default instance;

// Set chatServerUrl conditionally
export const chatServerUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000" // Development Chat Server URL
    : "https://urja-talents-backend-xtk2.onrender.com"; // Production Chat Server URL
