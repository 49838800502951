import EditClassDetailsPage from "./EditClassDetails";

export const EditClassDetailsPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/course/:id",
      exact: true,
      component: EditClassDetailsPage
    }
  ]
};
