import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ImFilesEmpty } from "react-icons/im";
import { IoMdCloudDownload } from "react-icons/io";
import "./FileModal.css"; // Add a CSS file for custom styling
import { baseAwsUrl, truncateText } from "../../utils/MyAPI";

const FileModal = ({ files, buttonText = "Work Seats" }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      {/* Trigger Button */}
      <button className="ms-3 mb-3 new-button" onClick={handleShow}>
        {buttonText}
      </button>

      {/* Modal */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Work Seat's</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap gap-3 justify-content-start">
            {files && files.length > 0 ? (
              files.map((file, index) => (
                <div
                  key={index}
                  className="file-card shadow-sm rounded-3 border d-flex flex-column align-items-center p-3"
                >
                  <ImFilesEmpty size={32} className="file-icon mb-2" />
                  <p className="file-name text-truncate" title={file}>
                    {truncateText(file, 1)}
                  </p>
                  <Link
                    to={baseAwsUrl + file}
                    target="_blank"
                    download
                    className="download-btn mt-2 text-decoration-none"
                  >
                    <IoMdCloudDownload size={22} className="me-1" />
                    Download
                  </Link>
                </div>
              ))
            ) : (
              <p>No files available.</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileModal;
