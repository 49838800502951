import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";

const Chats = [
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
];

const ChatReport = () => {
  const token = useSelector((state) => state.token.value);
  const [dataLoading, setDataLoading] = useState(false);
  const [chats, setChats] = useState([]);

  const fetchAllReportedChat = async (token) => {
    try {
      setDataLoading(true);
      let res = await MyAPI.GET("/chat/report", token);
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setChats(apiData);
      } else {
        toast.error(message || "Error Fetching data.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchAllReportedChat(token);
    }
  }, [token]);

  const [deletedId, setDeletedId] = useState(null);

  const deleteHandler = async (id, index) => {
    try {
      setDeletedId(index);
      let res = await MyAPI.DELETE(`/chat/report/${id}`, token);
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        fetchAllReportedChat(token);
        toast.success(message);
      } else {
        toast.error(message || "Error Deleting data.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDeletedId(null);
    }
  };
  return (
    <>
      <h3>Chat Reports</h3>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white text-truncate">S.No.</th>
            <th className="text-white text-truncate">Reporter Name</th>
            <th className="text-white text-truncate">Email</th>
            <th className="text-white text-truncate">Phone</th>
            <th className="text-white text-truncate">Repoted Message</th>
            <th className="text-white text-truncate">Date</th>
            <th className="text-white text-truncate">Action</th>
          </tr>
        </thead>
        <tbody>
          {chats &&
            chats.length > 0 &&
            [...chats].reverse().map((chat, index) => (
              <tr key={chat._id} className="text-center bg-light">
                <td className="text-truncate">{index + 1}</td>
                <td className="text-truncate">
                  {chat?.reporter_id?.name || "N/A"}
                </td>
                <td className="text-truncate">
                  {chat?.reporter_id?.email || "N/A"}
                </td>
                <td className="text-truncate">
                  {chat?.reporter_id?.phone || "N/A"}
                </td>
                <td className="text-truncate">
                  {chat?.message_id?.text || "N/A"}
                </td>
                <td className="text-truncate">
                  {chat?.message_id?.createdAt.substring(0, 10) || "N/A"}
                </td>
                <td>
                  <Button
                    variant="danger"
                    className="btn-sm"
                    disabled={deletedId === index}
                    onClick={() => deleteHandler(chat?._id, index)}
                  >
                    {deletedId === index ? "Deleing..." : <MdDelete />}
                  </Button>
                </td>
              </tr>
            ))}
          {dataLoading && (
            <tr>
              <td colSpan={7}>Loading...</td>
            </tr>
          )}
          {!dataLoading && chats && chats.length === 0 && (
            <tr>
              <td colSpan={7}>No Data Found.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default ChatReport;
