import React, { useEffect, useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Col, Row, Modal, Button, Form } from "react-bootstrap";
import { IoSchoolOutline, IoStar, IoStarOutline, IoStarSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { FaBook, FaHeart, FaSwatchbook } from "react-icons/fa";
import { IoMdClose, IoMdTime } from "react-icons/io";
import styled from "styled-components";
import { toast } from "react-toastify";
import ParentFooter from "../../components/ParentFooter";
import { TiBook, TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
import { SiGoogleclassroom } from "react-icons/si";
import { truncateText } from "../../utils/MyAPI";

const Favorotes = () => {
  const [favTeachers, setFavTeachers] = useState([]);
  const [favCourses, setFavCourses] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.token.value);

  const getFavTeachers = async () => {
    try {
      const res = await axios.get("/parent/fav-teacher", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res.data);

      if (res.data.res_type === "success") {
        setFavTeachers(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFavCourses = async () => {
    try {
      const res = await axios.get("/parent/fav-course", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res.data);

      if (res.data.res_type === "success") {
        setFavCourses(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFevTeacher = async (teacherId) => {
    try {
      const res = await axios.post(
        "/parent/fav-teacher",
        {
          teacher_id: teacherId,
          action: "REMOVE", // ADD or REMOVE
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res.data);

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        getFavTeachers();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFevCourse = async (courseId) => {
    try {
      const res = await axios.post(
        "/parent/fav-course",
        {
          course_id: courseId,
          action: "REMOVE", // ADD or REMOVE
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res.data);

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        getFavCourses();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFavTeachers();
  }, []);

  useEffect(() => {
    getFavCourses();
  }, []);

  return (
    <OftadehLayout>
      <Row className="mt-3">
        <Col>
          <h5 className="mb-4">Favorite Teachers</h5>
        </Col>
      </Row>

      {favTeachers?.length > 0 ? (
        <Row className="position-relative pe-5">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 3 : 1}
            spaceBetween={20}
            modules={[Navigation, Autoplay, Pagination]}
            grabCursor={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="mySwiper pb-5"
          >
            {favTeachers &&
              favTeachers.map((teacher) => (
                <SwiperSlide key={teacher._id}>
                  <div className="parent_card2 border position-relative rounded-4 mb-3 ms-3 cursor-pointer">
                    <div
                      className="d-flex align-items-center justify-content-center shadow"
                      style={{
                        cursor: "pointer",
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "5%",
                        right: "5%",
                        backgroundColor: "#f2f0f5",
                        zIndex: 999,
                      }}
                    >
                      <FaHeart
                        onClick={() =>
                          handleFevTeacher(teacher?.teacher_id?._id)
                        }
                        className="fs-5"
                        color="red"
                      />
                    </div>
                    <div className="parent_card2_top d-flex align-items-center justify-content-center pe-2 py-2 overflow-hidden position-relative">
                      <img
                        src={teacher?.teacher_id?.image_key}
                        className="rounded-3"
                        style={{ objectPosition: 'top' }}
                        alt=""
                      />
                    </div>
                    <div className="parent_card2_bottom p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 fw-bold d-flex align-items-center">
                          <IoStarSharp className="text_yellow me-2 fs-6" />{" "}
                          {teacher?.teacher_id?.rating || 0}
                          {/* <span className="ms-2 text-secondary">
                            ( {teacher?.teacher_id?.rating_count || 0} reviews )
                          </span> */}
                        </p>
                        &nbsp;
                        <p onClick={() => navigate(`/teacher/profile/${teacher.teacher_id._id}`)} className="m-0 fw-bold">
                          {teacher?.teacher_id?.firstName}{" "}
                          {teacher?.teacher_id?.lastName}
                        </p>
                      </div>

                      {/* <div className="mt-3">
                        <FaSwatchbook
                          size={18}
                          className="me-3"
                          style={{ color: "#ff5733" }}
                        />
                        {teacher?.teacher_id?.teacher_detail_id?.experince ||
                          "N/A"}{" "}
                        Years Experience
                      </div>
                      <div className="mt-1">
                        <FaBook
                          size={18}
                          className="me-3"
                          style={{ color: "#388707" }}
                        />
                        {
                          teacher?.teacher_id?.teacher_detail_id
                            ?.qualifications[0]?.level
                        }{" "}
                        in{" "}
                        {
                          teacher?.teacher_id?.teacher_detail_id
                            ?.qualifications[0]?.degree_name
                        }
                      </div>
                      <div className="mt-1">
                        <SiGoogleclassroom
                          size={18}
                          className="me-3"
                          style={{ color: "#5733ff" }}
                        />
                        {teacher?.teacher_id?.teacher_detail_id
                          ?.completed_classes || 0}{" "}
                        Completed Classes
                      </div>

                      <div className="d-flex flex-wrap gap-2 mt-3">
                        {teacher?.teacher_id?.teacher_detail_id?.grades.map(
                          (g, i) => (
                            <div
                              key={i}
                              className="px-3 py-1 border rounded-5 cursor-pointer"
                              style={{ width: "fit-content" }}
                            >
                              {g.subject}
                            </div>
                          )
                        )}

                        <div className="w-100 h-100">
                          <span
                            onClick={() => navigate("/parent/teacher/123456")}
                            className="float-end new-button rounded-5 border cursor-pointer"
                          >
                            View
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </Row>
      ) : (
        <h5 className="my-5 text-center">No Favorite Teachers Found</h5>
      )}

      <StyledContainer>
        <Row className="mt-3">
          <Col>
            <h5 className="mb-4">Favorite Courses</h5>
          </Col>
        </Row>

        {favCourses?.length > 0 ? (
          <Row className="position-relative pe-5">
            <Swiper
              slidesPerView={window.innerWidth > 768 ? 3 : 1}
              spaceBetween={20}
              modules={[Navigation, Autoplay, Pagination]}
              grabCursor={true}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              className="mySwiper pb-5"
            >
              {favCourses &&
                favCourses.map((course) => (
                  <SwiperSlide key={course._id}>
                    <Cards1
                      course={course?.course_id}
                      img={course?.course_id?.thumbnail_key}
                      tags={course?.course_id?.course_tags}
                      board={course?.course_id?.board}
                      grade={course?.course_id?.grade}
                      startCBtn={true}
                      paybtn={true}
                      completed={true}
                      reviews_count={course?.course_id?.reviews_count}
                      price={course?.course_id?.price}
                      handleFevCourse={() =>
                        handleFevCourse(course?.course_id?._id)
                      }
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </Row>
        ) : (
          <h5 className="my-5 text-center">No Favourite Courses Found</h5>
        )}
      </StyledContainer>
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .favorite_right_arrow1,
  .favorite_left_arrow1,
  .favorite_right_arrow2,
  .favorite_left_arrow2 {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background: var(--yellow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    > svg {
      color: #000000;
    }
  }
  .favorite_right_arrow1,
  .favorite_right_arrow2 {
    right: 5%;
  }
  .favorite_left_arrow1,
  .favorite_left_arrow2 {
    left: -1%;
  }
`;

const Cards1 = ({
  img,
  badge,
  holdbtn,
  paybtn,
  completed,
  startCBtn,
  joinbtn,
  continuebtn,
  demoBtn,
  tags,
  board,
  grade,
  demoFree,
  demoPaid,
  joinNow,
  joinNowDisabled,
  restartBtn,
  getCertificateBtn,
  reviews_count,
  price,
  handleFevCourse,
  course,
}) => {
  const [showModalHold, setShowModalHold] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const navigate = useNavigate()

  const handleButtonClickHold = () => {
    setShowModalHold(true);
  };

  const handleCloseModelHold = () => {
    setShowModalHold(false);
  };

  const handleCloseFeedbackModal = () => {
    setFeedbackModal(false);
  };

  const handlePaymentSuccess = () => {
    Swal.fire({
      title: "Payment",
      text: "Redirecting to payment page, please wait...",
      icon: "success",
      timer: 5000, // 2 seconds
      timerProgressBar: true,
      // didClose: () => {
      //   history.push('/payment'); // Redirect to payment page
      // }
    });
  };

  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img
          // onClick={onCourseClick ? onCourseClick : null}
          className="cource_img cursor-pointer rounded-3 px-3"
          style={{ height: 200, width: "90%" }}
          src={"https://hariom-bucket.s3.ap-south-1.amazonaws.com/" + img}
          alt=""
        />
        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FaHeart
            onClick={() => handleFevCourse()}
            color="red"
            className="fs-5"
          />
        </div>

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">{course?.course_tags[0]}</button>
          <h6 className="m-0 fw-semibold">Rs. {course?.price}<sub className="text-dark">/ person</sub></h6>
        </div>

        <h5
          onClick={() =>
            navigate("/course", {
              state: { course },
            })
          }
          style={{ cursor: "pointer" }}
          className="mt-3 px-3"
        >
          {truncateText(course?.title, 5)}
        </h5>

        <div
          className="d-flex align-items-center justify-content-start gap-2 overflow-auto px-3 custom-scroll-bar"
          style={{ whiteSpace: "nowrap" }}
        >
          <p className="text-dark px-3 py-1 rounded-5 border">
            {course?.ageGroup || "10 - 15 year"}
          </p>
          <p className="text-dark px-3 py-1 rounded-5 border">
            {course?.course_type || "Not specified"}
          </p>
          {course?.course_tags?.length > 0 ? (
            course.course_tags.map((item, index) => (
              <p
                key={`course-tag-${index}`}
                className="text-dark px-3 py-1 rounded-5 border"
                style={{ display: "inline-block" }}
              >
                {item}
              </p>
            ))
          ) : (
            <p className="text-dark px-3 py-1 rounded-5 border">
              No tags available
            </p>
          )}
          {course?.modules?.length > 0 ? (
            course.modules.map((item, index) => (
              <p
                key={`module-${index}`}
                className="text-dark px-3 py-1 rounded-5 border"
                style={{ display: "inline-block" }}
              >
                {item}
              </p>
            ))
          ) : (
            <p className="text-dark px-3 py-1 rounded-5 border">
              No modules available
            </p>
          )}
        </div>

        {/* <div
          className={`${holdbtn ? "bottom" : ""} ${paybtn ? "bottom" : ""} ${startCBtn ? "bottom" : ""
            } mt-2 py-2 px-3 d-flex align-items-center justify-content-between`}
        >
          {holdbtn && (
            <button className="py-1 px-3" onClick={handleButtonClickHold}>
              hold
            </button>
          )}
          {paybtn && <button className="py-1 px-3">pay</button>}
          {demoBtn && <button className="py-1 px-3">Book Demo</button>}
          {startCBtn && <button className="py-1 px-3">Start Course</button>}
        </div> */}

        {/* {joinbtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Join Now</button>
          </div>
        )} */}

        {/* {continuebtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Continue</button>
          </div>
        )} */}

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3 pb-3">
          <div className="user d-flex align-items-center gap-2">
            <div className="user_img">
              <img src={course?.teacher_id?.image_key} alt="" />
            </div>
          </div>
          <p className="m-0 text-secondary">
            {course?.teacher_id?.firstName +
              " " +
              course?.teacher_id?.lastName}
          </p>
          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p>
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" />{" "}
            {course?.duration_time || course?.duration}
          </p>
          <p className="m-0">
            <IoSchoolOutline className="fs-5 me-1 mb-1" />{" "}
            {course?.students_count}
          </p>
        </div>

        {/* {demoFree && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Take a Demo ( Free )
            </button>
          </div>
        )} */}

        {/* {getCertificateBtn && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Get Certificate
            </button>
          </div>
        )} */}

        {/* {restartBtn && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Restart Now
            </button>
          </div>
        )} */}

        {/* {joinNow && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-between mb-2 px-2">
            <button className="py-1 px-3">Enroll Now</button>
            <div className="action">
              <span
                onClick={handlePaymentSuccess}
                className="px-1 cursor-pointer py-1 rounded-3 border"
                style={{ backgroundColor: "#b8e3b8" }}
              >
                <TiTick size={22} color="green" />
              </span>
              <span
                onClick={() => setFeedbackModal(true)}
                className="px-1 cursor-pointer py-1 rounded-3 border ms-2 me-3"
                style={{ backgroundColor: "#f2c1b8" }}
              >
                <IoMdClose size={22} color="red" />
              </span>
            </div>
          </div>
        )} */}

        {/* {joinNowDisabled && (
          <div className="h-100 w-100">
            <button
              disabled
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Enroll Now
            </button>
          </div>
        )} */}

        {/* {demoPaid && (
          <div className="h-100 border-top  px-3 py-2 w-100 d-flex align-items-center gap-2">
            <button className="py-1 px-2">Enrol Now</button>
            <button className="py-1 px-2" onClick={() => HandleCancel()}>
              Cancel
            </button>
          </div>
        )} */}

        {/* {demoPaid && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Book a Demo (₹ 100 )
            </button>
          </div>
        )} */}
      </div>

      <Modal
        show={showModalHold}
        onHide={handleCloseModelHold}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Hold Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to hold this class?</p>
          <div className="mb-3">
            <label htmlFor="start-date" className="form-label">
              Start Date
            </label>
            <input type="date" className="form-control" id="start-date" />
          </div>
          <div className="mb-3">
            <label htmlFor="end-date" className="form-label">
              End Date
            </label>
            <input type="date" className="form-control" id="end-date" />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseModelHold}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseModelHold}>
            Hold
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={feedbackModal}
        onHide={handleCloseFeedbackModal}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
            </Col>
          </Row>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            {/* <Form.Label>Feedback</Form.Label> */}
            <Form.Control
              className="mt-3"
              as="textarea"
              placeholder="Enter Desc..."
              rows={3}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseFeedbackModal}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseFeedbackModal}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Favorotes;
