import React, { useEffect, useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { IoSchoolOutline, IoStar, IoStarOutline } from "react-icons/io5";
import Swal from "sweetalert2";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "../../utils/axios";
import { baseAwsUrl, MyAPI, truncateText } from "../../utils/MyAPI";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from "react-toastify"
// import required modules
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { IoMdClose, IoMdTime } from "react-icons/io";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import FeedbackModal from "./FeedbackModal";
import { FiHeart } from "react-icons/fi";
import { TiBook, TiTick } from "react-icons/ti";

import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { FaHeart } from "react-icons/fa";

const Courses = (props) => {
  const history = props;
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const token = useSelector((state) => state.token.value)

  const handleDropdownChange = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };

  const handleCoursesView = () => {
    navigate("/parent/dashboard/courses/142512");
  };

  const handleEnrollCoursesView = () => {
    navigate("/course");
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    navigate(`/parent/dashboard/courses/search/${searchValue}`);
  };

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedCourseType, setSelectedCourseType] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedMinPrice, setSelectedMinPrice] = useState("");
  const [selectedMaxPrice, setSelectedMaxPrice] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/home/courses?page=&limit=&category=${selectedCategory}&subject=${selectedSubject}&grade=${selectedGrade}&board=${selectedBoard}&course_type=${selectedCourseType}&language=${selectedLanguage}&status=${selectedStatus}&min_price=${selectedMinPrice}&max_price=${selectedMaxPrice}`
      );

      if (res.data.res_type === "success") {
        setCourses(res.data.data.courses);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [
    selectedCategory,
    selectedSubject,
    selectedGrade,
    selectedBoard,
    selectedCourseType,
    selectedLanguage,
    selectedStatus,
    selectedMinPrice,
    selectedMaxPrice,
  ]);


  const [allFevCourse, setAllFevCourse] = useState([])

  const fetchFoveateCourse = async (token) => {
    try {
      let res = await MyAPI.GET('/parent/fav-course', token);
      let { res_type, data: apiData, message } = res.data || res
      if (res_type === 'success') {
        let myArr = [];
        apiData.forEach((item) => {
          myArr.push(item.course_id._id.toString())
        })
        console.log("course fav:", myArr)
        setAllFevCourse(myArr)
      } else {
        setCourses([])
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const toggleFavCourse = async (id, isFev) => {
    try {
      let res = await MyAPI.POST('/parent/fav-course', {
        course_id: id,
        action: isFev ? 'REMOVE' : 'ADD'
      }, token)
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === 'success') {
        fetchFoveateCourse(token)
        toast.success(message)
      } else {
        toast.error(message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (token) {
      fetchFoveateCourse(token)
    }
  }, [token])

  return (
    <OftadehLayout>
      <h1>Courses</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <FeedbackModal
        show={showFeedbackModal}
        toggleModal={toggleFeedbackModal}
      />

      <StyledContainer>
        <Row>
          <Col md={3}>
            <Form.Group controlId="nameInput">
              <Form.Control
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="shadow rounded-5"
                type="text"
                placeholder="Search Course by Name, Subject, Grade, Board..."
              />
            </Form.Group>
          </Col>
          <Col md={1}>
            <button onClick={handleSearch} className="new-button">
              Search
            </button>
          </Col>
          <Col md={8} className="d-flex align-items-center justify-content-end">
            {" "}
            <button
              className="new-button"
              variant="outlined"
              size="medium"
              onClick={handleButtonClick}
            >
              Help
            </button>{" "}
          </Col>
        </Row>

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          style={{ zIndex: "1111", marginTop: "10vh" }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Manual Demo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="mt-3" md={4}>
                <Form.Label>Child Name</Form.Label>
                <Form.Control
                  className="shadow rounded-5"
                  type="text"
                  placeholder="John Doe"
                />
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Label>Age</Form.Label>
                <Form.Control
                  className="shadow rounded-5"
                  type="text"
                  placeholder="18 years"
                />
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Label>STD</Form.Label>
                <Form.Control
                  className="shadow rounded-5"
                  type="text"
                  placeholder="123-122-1232"
                />
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Label>Mobile No.</Form.Label>
                <Form.Control
                  className="shadow rounded-5"
                  type="text"
                  placeholder="+91 1234567890"
                />
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="shadow rounded-5"
                  type="email"
                  placeholder="example@email.com"
                />
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Select Courses</Form.Label>
                  <Form.Control className="shadow rounded-5" as="select">
                    <option value="course1">Course 1</option>
                    <option value="course2">Course 2</option>
                    <option value="course3">Course 3</option>
                    <option value="course4">Course 4</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Courses Mode</Form.Label>
                  <Form.Control className="shadow rounded-5" as="select">
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Courses Shift</Form.Label>
                  <Form.Control className="shadow rounded-5" as="select">
                    <option value="Online">Day</option>
                    <option value="Offline">Night</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Preferred Teacher</Form.Label>
                  <Form.Control className="shadow rounded-5" as="select">
                    <option value="Online">teacher 1</option>
                    <option value="Offline">teacher 2</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Select Child</Form.Label>
                  <Form.Control className="shadow rounded-5" as="select">
                    <option value="Online">child 1</option>
                    <option value="Offline">child 2</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Label>Preferred Date</Form.Label>
                <Form.Control className="shadow rounded-5" type="date" />
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Label>Preferred Time</Form.Label>
                <Form.Control className="shadow rounded-5" type="time" />
              </Col>
              <Col className="mt-3" md={4}>
                <hr className="bg-transparent border-0" />
                {/* <Button className='shadow rounded-5 border-warning text-warning' variant="outlined">Book Demo</Button> */}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outlined"
              className="me-3 bg-danger text-white border-danger"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            {/* You can add additional buttons or functionality here */}
            <Button variant="outlined" onClick={handleCloseModal}>
              Send Request
            </Button>
          </Modal.Footer>
        </Modal>

        <Row className="pe-5">
          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Sort By</option>
              <option value="1">Popular Teacher</option>
              <option value="3">High To Low (Price)</option>
              <option value="3">High To Low (Rating)</option>
            </Form.Select>
          </Col>
          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Subject
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Subject</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Hindi</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>English</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Maths</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Grade
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Grade</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>A+</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>A</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>B+</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Board
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Board</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>CBSE</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>ICSE</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>State Board</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Experience
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Experience</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>1-3 Years</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>3-5 Years</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>5-8 Years</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>8-12 Years</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>12+ Years</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Rating
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Rating</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>1</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>2</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>3</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>4</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>5</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Class Type</option>
              <option value="1">1-on-1</option>
              <option value="2">Group Classes</option>
              <option value="2">Both </option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Type</option>
              <option value="2">Academic </option>
              <option value="2">Extra Academic </option>
              <option value="2">Curriculum </option>
              <option value="2">Competitive Exams</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Type</option>
              <option value="2">Ongoing </option>
              <option value="2">One Time</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Days</option>
              <option value="1">Sunday</option>
              <option value="2">Monday</option>
              <option value="3">Tuesday</option>
              <option value="4">Wednesday</option>
              <option value="5">Thursday</option>
              <option value="6">Friday</option>
              <option value="7">Saturday</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Time</option>
              <option value="1">Morning</option>
              <option value="2">Evening</option>
              <option value="3">Afternoon</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Qualification
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Qualification</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Bachelor</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Master</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Phd</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        {/* <Row className="mt-5">
          <Col>
            <h3 className="mb-4">Today's Courses</h3>
          </Col>
        </Row> */}
        {/* 
        <Row className="position-relative pe-5">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 3 : 1}
            spaceBetween={20}
            modules={[Navigation, Autoplay, Pagination]}
            grabCursor={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="mySwiper pt-4 pb-5"
          >
            <SwiperSlide>
              <Cards
                img={c1}
                completed={true}
                onCourseClick={handleEnrollCoursesView}
                age="17+"
                type="1-on-1"
                type2="Academic"
                type3="Short course"
                board="CBSE"
                grade="A+"
                demoFree={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c2}
                completed={true}
                onCourseClick={handleEnrollCoursesView}
                age="17+"
                type="1-on-1"
                type2="Academic"
                type3="Short course"
                board="CBSE"
                grade="A+"
                demoPaid={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c2}
                completed={true}
                onCourseClick={handleEnrollCoursesView}
                age="17+"
                type="1-on-1"
                type2="Academic"
                type3="Short course"
                board="CBSE"
                grade="A+"
                getCertificateBtn={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c3}
                completed={true}
                onCourseClick={handleEnrollCoursesView}
                age="17+"
                type="1-on-1"
                type2="Academic"
                type3="Short course"
                board="CBSE"
                grade="A+"
                joinNow={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c4}
                completed={true}
                onCourseClick={handleEnrollCoursesView}
                age="17+"
                type="1-on-1"
                type2="Academic"
                type3="Short course"
                board="CBSE"
                grade="A+"
                joinNowDisabled={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c4}
                completed={true}
                onCourseClick={handleEnrollCoursesView}
                age="17+"
                type="1-on-1"
                type2="Academic"
                type3="Short course"
                board="CBSE"
                grade="A+"
                restartBtn={true}
              />
            </SwiperSlide>
          </Swiper>
        </Row> */}

        <Row className="mt-5">
          <Col>
            <h3 className="mb-4">Active Courses</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 3 : 1}
            spaceBetween={20}
            modules={[Navigation, Autoplay, Pagination]}
            grabCursor={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="mySwiper pt-4 pb-5"
          >
            {courses.map((course, idx) => (
              <SwiperSlide>
                <Col key={idx} className="mb-4">
                  <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
                    <img
                      className="cource_img rounded-3 px-3"
                      src={baseAwsUrl + course?.thumbnail_key}
                      alt=""
                      style={{ height: 200, width: "90%" }}
                    />
                    <div onClick={() => toggleFavCourse(course._id, allFevCourse.includes(course._id))} className="heart d-flex align-items-center justify-content-center shadow">
                      {
                        allFevCourse.includes(course._id) ? (
                          <FaHeart className="fs-5 text-danger" />
                        ) : (
                          <FiHeart className="fs-5" />
                        )
                      }
                    </div>

                    <div className="d-flex px-3 align-items-center justify-content-between mt-3">
                      <button className="px-3">{course?.course_tags[0]}</button>
                      <h6 className="m-0 fw-semibold">Rs. {course?.price}<sub className="text-dark">/ person</sub></h6>
                    </div>

                    <h5
                      onClick={() =>
                        navigate("/course", {
                          state: { course },
                        })
                      }
                      style={{ cursor: "pointer" }}
                      className="mt-3 px-3"
                    >
                      {truncateText(course?.title, 5)}
                    </h5>

                    <div
                      className="d-flex align-items-center justify-content-start gap-2 overflow-auto px-3 custom-scroll-bar"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <p className="text-dark px-3 py-1 rounded-5 border">
                        {course?.ageGroup || "10 - 15 year"}
                      </p>
                      <p className="text-dark px-3 py-1 rounded-5 border">
                        {course?.course_type || "Not specified"}
                      </p>
                      {course?.course_tags?.length > 0 ? (
                        course.course_tags.map((item, index) => (
                          <p
                            key={`course-tag-${index}`}
                            className="text-dark px-3 py-1 rounded-5 border"
                            style={{ display: "inline-block" }}
                          >
                            {item}
                          </p>
                        ))
                      ) : (
                        <p className="text-dark px-3 py-1 rounded-5 border">
                          No tags available
                        </p>
                      )}
                      {course?.modules?.length > 0 ? (
                        course.modules.map((item, index) => (
                          <p
                            key={`module-${index}`}
                            className="text-dark px-3 py-1 rounded-5 border"
                            style={{ display: "inline-block" }}
                          >
                            {item}
                          </p>
                        ))
                      ) : (
                        <p className="text-dark px-3 py-1 rounded-5 border">
                          No modules available
                        </p>
                      )}
                    </div>


                    <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3 pb-3">
                      <div className="user d-flex align-items-center gap-2">
                        <div className="user_img">
                          <img src={course?.teacher_id?.image_key} alt="" />
                        </div>
                      </div>
                      <p className="m-0 text-secondary">
                        {course?.teacher_id?.firstName +
                          " " +
                          course?.teacher_id?.lastName}
                      </p>
                      <div className="d-flex align-items-center gap-2">
                        <IoStar className="star" /> (4 Reviews)
                      </div>
                    </div>

                    <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
                      <p className="m-0">
                        <TiBook className="fs-5 me-1 mb-1" /> 05
                      </p>
                      <p className="m-0">
                        <IoMdTime className="fs-5 me-1 mb-1" />{" "}
                        {course?.duration_time || course?.duration}
                      </p>
                      <p className="m-0">
                        <IoSchoolOutline className="fs-5 me-1 mb-1" />{" "}
                        {course?.students_count}
                      </p>
                    </div>
                  </div>
                </Col>
              </SwiperSlide>
            ))}

            {/* <SwiperSlide>
              <Cards
                img={c2}
                completed={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c3}
                completed={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c4}
                completed={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide> */}
          </Swiper>
        </Row>

        {/* <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Demos</h3>
          </Col>
        </Row> */}

        {/* <Row className="position-relative pe-5">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 3 : 1}
            spaceBetween={20}
            modules={[Navigation, Autoplay, Pagination]}
            grabCursor={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="mySwiper pt-4 pb-5"
          >
            <SwiperSlide>
              <Cards
                img={c1}
                paybtn={true}
                startCBtn={true}
                demoBtn={true}
                onCourseClick={handleEnrollCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c2}
                paybtn={true}
                startCBtn={true}
                demoBtn={true}
                onCourseClick={handleEnrollCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c3}
                paybtn={true}
                startCBtn={true}
                demoBtn={true}
                onCourseClick={handleEnrollCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c4}
                paybtn={true}
                startCBtn={true}
                demoBtn={true}
                onCourseClick={handleEnrollCoursesView}
              />
            </SwiperSlide>
          </Swiper>
        </Row>

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">On-hold Courses</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 3 : 1}
            spaceBetween={20}
            modules={[Navigation, Autoplay, Pagination]}
            grabCursor={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="mySwiper pt-4 pb-5"
          >
            <SwiperSlide>
              <Cards
                img={c1}
                joinbtn={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c2}
                joinbtn={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c3}
                joinbtn={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c4}
                joinbtn={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
          </Swiper>
        </Row>

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Courses Cancelled</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 3 : 1}
            spaceBetween={20}
            modules={[Navigation, Autoplay, Pagination]}
            grabCursor={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="mySwiper pt-4 pb-5"
          >
            <SwiperSlide>
              <Cards
                img={c1}
                continuebtn={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c2}
                continuebtn={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c3}
                continuebtn={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c4}
                continuebtn={true}
                onCourseClick={handleCoursesView}
              />
            </SwiperSlide>
          </Swiper>
        </Row>

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">Suggested Courses</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 3 : 1}
            spaceBetween={20}
            modules={[Navigation, Autoplay, Pagination]}
            grabCursor={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="mySwiper pt-4 pb-5"
          >
            <SwiperSlide>
              <Cards
                img={c1}
                paybtn={true}
                onCourseClick={handleCoursesView}
                demoBtn={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c2}
                paybtn={true}
                onCourseClick={handleCoursesView}
                demoBtn={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c3}
                paybtn={true}
                onCourseClick={handleCoursesView}
                demoBtn={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c4}
                paybtn={true}
                onCourseClick={handleCoursesView}
                demoBtn={true}
              />
            </SwiperSlide>
          </Swiper>
        </Row>

        <Row className="mt-4">
          <Col>
            <h3 className="mb-4">New Courses</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            slidesPerView={window.innerWidth > 768 ? 3 : 1}
            spaceBetween={20}
            modules={[Navigation, Autoplay, Pagination]}
            grabCursor={true}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="mySwiper pt-4 pb-5"
          >
            <SwiperSlide>
              <Cards
                img={c1}
                paybtn={true}
                onCourseClick={handleCoursesView}
                demoBtn={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c2}
                paybtn={true}
                onCourseClick={handleCoursesView}
                demoBtn={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c3}
                paybtn={true}
                onCourseClick={handleCoursesView}
                demoBtn={true}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Cards
                img={c4}
                paybtn={true}
                onCourseClick={handleCoursesView}
                demoBtn={true}
              />
            </SwiperSlide>
          </Swiper>
        </Row> */}
      </StyledContainer>
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .input_parent {
    border: 2px solid #e5e5e5;
    width: fit-content;
    border-radius: 100px;

    input {
      width: 350px;
      background: transparent;
    }

    button {
      background: var(--color-primary);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border: none;
    }
  }
  .courses_right_arrow1,
  .courses_left_arrow1,
  .courses_right_arrow2,
  .courses_left_arrow2,
  .courses_right_arrow3,
  .courses_left_arrow3,
  .courses_right_arrow4,
  .courses_left_arrow4,
  .courses_right_arrow5,
  .courses_left_arrow5,
  .courses_right_arrow6,
  .courses_left_arrow6,
  .courses_right_arrow7,
  .courses_left_arrow7 {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background: var(--yellow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    > svg {
      color: #000000;
    }
  }
  .courses_right_arrow1,
  .courses_right_arrow2,
  .courses_right_arrow3,
  .courses_right_arrow4,
  .courses_right_arrow5,
  .courses_right_arrow6,
  .courses_right_arrow7 {
    right: 5%;
  }
  .courses_left_arrow1,
  .courses_left_arrow2,
  .courses_left_arrow3,
  .courses_left_arrow4,
  .courses_left_arrow5,
  .courses_left_arrow6,
  .courses_left_arrow7 {
    left: -1%;
  }
`;

export default Courses;

const Cards = ({
  img,
  badge,
  holdbtn,
  paybtn,
  completed,
  startCBtn,
  joinbtn,
  continuebtn,
  onCourseClick,
  demoBtn,
  isEnrollCourse,
  age,
  type,
  type2,
  type3,
  board,
  grade,
  demoFree,
  demoPaid,
  joinNow,
  joinNowDisabled,
  restartBtn,
  getCertificateBtn,
}) => {
  const [showModalHold, setShowModalHold] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const handleButtonClickHold = () => {
    setShowModalHold(true);
  };

  const handleCloseModelHold = () => {
    setShowModalHold(false);
  };

  const HandleCancel = () => {
    setFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setFeedbackModal(false);
  };

  const handlePaymentSuccess = () => {
    Swal.fire({
      title: "Payment",
      text: "Redirecting to payment page, please wait...",
      icon: "success",
      timer: 5000, // 2 seconds
      timerProgressBar: true,
      // didClose: () => {
      //   history.push('/payment'); // Redirect to payment page
      // }
    });
  };

  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img
          onClick={onCourseClick ? onCourseClick : null}
          className="cource_img cursor-pointer rounded-3 px-3"
          style={{ width: "90%" }}
          src={img}
          alt=""
        />
        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div>

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Subject</button>
        </div>

        <h5 className="mt-3 px-3">Course Name</h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
          </div>
          <p className="m-0 text-black">Robert Fox</p>

          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        {badge && (
          <div className="d-flex align-items-center justify-content-between mt-3 px-3 pb-3">
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Online
            </span>
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Paid
            </span>

            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Fixed
            </span>
          </div>
        )}

        <div className="d-flex px-2 align-items-center  justify-content-between mt-2">
          <div className="d-flex flex-wrap gap-3">
            {age && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {age}
              </div>
            )}
            {type && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {type}
              </div>
            )}
            {type2 && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {type2}
              </div>
            )}
            {type3 && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {type3}
              </div>
            )}
            {board && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {board}
              </div>
            )}
            {grade && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {grade}
              </div>
            )}
          </div>
        </div>
        {completed && (
          <div className="w-100 d-flex justify-content-end ">
            <span
              className="badge text-black me-3"
              style={{
                background: "var(--yellow-color)",
                height: "fit-content",
              }}
            >
              36% Completed
            </span>
          </div>
        )}

        <div
          className={`${holdbtn ? "bottom" : ""} ${paybtn ? "bottom" : ""} ${startCBtn ? "bottom" : ""
            } mt-2 py-2 px-3 d-flex align-items-center justify-content-between`}
        >
          {holdbtn && (
            <button className="py-1 px-3" onClick={handleButtonClickHold}>
              hold
            </button>
          )}
          {paybtn && <button className="py-1 px-3">pay</button>}
          {demoBtn && <button className="py-1 px-3">Book Demo</button>}
          {startCBtn && <button className="py-1 px-3">Start Course</button>}
        </div>

        {joinbtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Join Now</button>
          </div>
        )}

        {continuebtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Continue</button>
          </div>
        )}

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          {/* <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p> */}
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          {/* <p className="m-0"> */}
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
          {/* </p> */}
        </div>

        {demoFree && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Take a Demo ( Free )
            </button>
          </div>
        )}

        {getCertificateBtn && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Get Certificate
            </button>
          </div>
        )}

        {restartBtn && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Restart Now
            </button>
          </div>
        )}

        {joinNow && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-between mb-2 px-2">
            <button className="py-1 px-3">Enroll Now</button>
            <div className="action">
              <span
                onClick={handlePaymentSuccess}
                className="px-1 cursor-pointer py-1 rounded-3 border"
                style={{ backgroundColor: "#b8e3b8" }}
              >
                <TiTick size={22} color="green" />
              </span>
              <span
                onClick={() => setFeedbackModal(true)}
                className="px-1 cursor-pointer py-1 rounded-3 border ms-2 me-3"
                style={{ backgroundColor: "#f2c1b8" }}
              >
                <IoMdClose size={22} color="red" />
              </span>
            </div>
          </div>
        )}

        {joinNowDisabled && (
          <div className="h-100 w-100">
            <button
              disabled
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Enroll Now
            </button>
          </div>
        )}

        {/* {demoPaid && (
          <div className="h-100 border-top  px-3 py-2 w-100 d-flex align-items-center gap-2">
            <button className="py-1 px-2">Enrol Now</button>
            <button className="py-1 px-2" onClick={() => HandleCancel()}>
              Cancel
            </button>
          </div>
        )} */}

        {demoPaid && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Book a Demo (₹ 100 )
            </button>
          </div>
        )}
      </div>

      <Modal
        show={showModalHold}
        onHide={handleCloseModelHold}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Hold Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to hold this class?</p>
          <div className="mb-3">
            <label htmlFor="start-date" className="form-label">
              Start Date
            </label>
            <input type="date" className="form-control" id="start-date" />
          </div>
          <div className="mb-3">
            <label htmlFor="end-date" className="form-label">
              End Date
            </label>
            <input type="date" className="form-control" id="end-date" />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseModelHold}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseModelHold}>
            Hold
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={feedbackModal}
        onHide={handleCloseFeedbackModal}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
            </Col>
          </Row>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            {/* <Form.Label>Feedback</Form.Label> */}
            <Form.Control
              className="mt-3"
              as="textarea"
              placeholder="Enter Desc..."
              rows={3}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseFeedbackModal}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseFeedbackModal}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
