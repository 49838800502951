import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaBook, FaRegHeart, FaSwatchbook } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { SiGoogleclassroom } from "react-icons/si";
import { Button } from "react-bootstrap";
import { FiHeart } from "react-icons/fi";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
const Card2 = ({
  img,
  head,
  review,
  time,
  blogIcon,
  blog,
  bottomRowText1,
  bottomRowText2,
  bottomRowText2Icon,
  deleteButton,
  blogLikeDislike,
  teacherDetails,
  redirect,
  isFevourite,
  fetchBlogsNew,
  blogID,
  isReviews,
  allFavTeachers,
  likeAction,
  teacher_id
}) => {
  let navigate = useNavigate();
  const token = useSelector((state) => state.token.value);

  const [like, setLike] = useState(false);

  const handlenavigatetoviewblog = () => {
    navigate("/blog/2354");
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleDeleteBlog = async (id) => {
    try {
      let res = await axios.delete(`/teacher/blog?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let { res_type, message, data } = res.data || res;
      if (res_type === "success") {
        toast.success("Blog Deleted successfully.");
        fetchBlogsNew();
      } else {
        toast.error(message || "Server error please try again later.");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleFevBlog = async (id) => {
    try {
      let res = await axios.patch(
        `/teacher/blog?id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let { res_type, message, data } = res.data || res;
      if (res_type === "success") {
        // toast.success('Blog Deleted successfully.')
        fetchBlogsNew();
      } else {
        toast.error(message || "Server error please try again later.");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div
      className="parent_card2 border position-relative rounded-4 mb-3 ms-3 cursor-pointer"
    >
      <div
        className="d-flex align-items-center justify-content-center shadow"
        style={{
          cursor: "pointer",
          height: "40px",
          width: "40px",
          borderRadius: "50%",
          position: "absolute",
          top: "5%",
          right: "5%",
          backgroundColor: "#f2f0f5",
          zIndex: 999,
        }}
        onClick={() => likeAction(teacher_id, allFavTeachers.includes(teacher_id))}
      >
        {allFavTeachers.includes(teacher_id) ? (
          <FaHeart
            className="fs-5"
            color="red"
          />
        ) : (
          <FiHeart className="fs-5" />
        )}
      </div>
      <div className="parent_card2_top d-flex align-items-center justify-content-center pe-2 py-2 overflow-hidden position-relative">
        <img onClick={redirect ? () => handleNavigate(redirect) : null} src={img} style={{ objectPosition: 'top' }} className="rounded-3" alt="" />
      </div>
      <div className="parent_card2_bottom p-3">
        {head && (
          <h5 className="fw-bold mt-1">
            {head}
            {blogLikeDislike && (
              <IconButton
                onClick={() => setLike(!like)}
                className="ms-5"
                aria-label="delete"
              >
                {like ? <FaHeart color="red" /> : <FaRegHeart />}
              </IconButton>
            )}
          </h5>
        )}
        {review && time && blogIcon && blog && (
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="d-flex align-items-center gap-4">
              <p className="m-0">{review}</p>
              <p className="m-0">{time}</p>
            </div>
            {deleteButton && (
              <MdDeleteOutline
                onClick={() => handleDeleteBlog(deleteButton)}
                className="fs-5 text-danger"
              />
            )}
            <p
              className="m-0 fw-bold text_yellow"
              onClick={handlenavigatetoviewblog}
            >
              {blog} {blogIcon}
            </p>
          </div>
        )}

        {/* {
          isFevourite && <>
            <div style={{ position: 'absolute', top: 10, right: 10 }}>
              <div className='rounded-circle bg-light d-flex align-items-center justify-content-center cursor-pointer' style={{ width: '40px', height: '40px' }} >
                <FaHeart size={20} style={{ color: 'red' }} />
              </div>
            </div>
          </>
        } */}

        {bottomRowText1 && bottomRowText2 && bottomRowText2Icon && (
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 fw-bold d-flex align-items-center">
              {bottomRowText2Icon} {bottomRowText2}{" "}
              {
                isReviews && (
                  <span className="ms-2 text-secondary">( 12 reviews )</span>
                )
              }
            </p>
            &nbsp;
            <p onClick={redirect ? () => handleNavigate(redirect) : null} className="m-0 fw-bold">{bottomRowText1}</p>
          </div>
        )}
        {teacherDetails && (
          <>
            <hr />
            <div className="mt-3">
              <FaSwatchbook
                size={18}
                className="me-3"
                style={{ color: "#ff5733" }}
              />
              12 Years Experience
            </div>
            <div className="mt-1">
              <FaBook size={18} className="me-3" style={{ color: "#388707" }} />
              Master in English
            </div>
            <div className="mt-1">
              <SiGoogleclassroom
                size={18}
                className="me-3"
                style={{ color: "#5733ff" }}
              />
              100+ Completed Classes
            </div>

            <div className="d-flex flex-wrap gap-2 mt-3">
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer"
                style={{ width: "fit-content" }}
              >
                Hindi
              </div>
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer"
                style={{ width: "fit-content" }}
              >
                English
              </div>
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer"
                style={{ width: "fit-content" }}
              >
                Math's
              </div>
              <div className="w-100 h-100">
                <span
                  onClick={() => navigate("/parent/teacher/123456")}
                  className="float-end new-button rounded-5 border cursor-pointer"
                >
                  {" "}
                  View
                </span>
              </div>
            </div>
            {/* <div className="px-3 mt-2 py-1 border-top text-center pt-4">
              
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Card2;
